import { useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Button,
  Stack,
  Container,
  FormLabel,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
  Alert,
  AlertIcon,
  Spinner,
} from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const SearchPage = (props: any) => {
  const toast = useToast();
  const toastIdRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const [email, setEmail] = useState("");
  const [isLibrary, setIsLibrary] = useState(true);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [successEan, setSuccessEan] = useState({
    email: "",
    ean_code: "",
    show: false,
  });
  const [result, setResult] = useState({
    email: "",
    name: "",
    cardNumber: "",
    surname: "",
    card_balance: 0,
    ean_code: null,
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/login";
      setIsLibrary(Boolean(localStorage.getItem("isLibrary")));
    }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSearch();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchTerm]);

  const onClose = () => setIsOpen(false);

  const handleSearch = async () => {
    if (searchTerm == "" || searchTerm.length < 5) {
      toast({
        title: `Inserisci un termine di ricerca valido`,
        position: "top-right" as any,
        isClosable: true,
        status: "warning",
      });
      return;
    }
    setLoading(true);
    const result = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          term: searchTerm.toLowerCase().trim(),
        }),
      }
    ).then((res) => res.json());
    if (result.status == "KO") {
      const serachOnlyShopiy = await fetch(
        `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/search/shopify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            term: searchTerm.toLowerCase().trim(),
          }),
        }
      ).then((res) => res.json());
      if (serachOnlyShopiy.firstName) {
        setResult({
          email: serachOnlyShopiy.email,
          surname: serachOnlyShopiy.firstName,
          name: serachOnlyShopiy.lastName,
          cardNumber: "",
          card_balance: 0,
          ean_code: serachOnlyShopiy.ean_code,
        });
        console.log(result);
        setLoading(false);
        return;
      }
      toast({
        title: `Nessun risultato trovato per ${searchTerm}`,
        position: "top-right" as any,
        isClosable: true,
        status: "warning",
      });
      setResult({
        email: "",
        surname: "",
        name: "",
        cardNumber: "",
        card_balance: 0,
        ean_code: null,
      });
    } else {
      setResult({
        email: result.email,
        name: result.name,
        surname: result.surname,
        cardNumber: result.cardNumber,
        card_balance: result.card_balance,
        ean_code: result.ean_code,
      });
    }
    setLoading(false);
  };

  const createNewVirtualCard = async () => {
    setDisabled(true);
    toastIdRef.current = toast({
      status: "info",
      position: "top-right" as any,
      render: () => (
        <Box
          color="white"
          px={4}
          pt={2}
          pb={2}
          backgroundColor={"#3182ce"}
          borderRadius="md"
        >
          <b style={{ marginRight: "5px" }}>Sto creando la carta</b>{" "}
          <Spinner size={"sm"} />
        </Box>
      ),
    }) as any;

    const resultCreate = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/dissociate-and-create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ email: email }),
      }
    ).then((res) => res.json());
    toast.close(toastIdRef.current as any);
    if (resultCreate.status == "OK") {
      toast({
        title: `Carta creata con successo`,
        position: "top-right" as any,
        isClosable: true,
        status: "success",
      });
      setSuccessEan({
        email: email,
        ean_code: resultCreate.data.ean_code,
        show: true,
      });
      setIsOpen(false);
      setTimeout(() => {
        window.location.href = `/email/${email}`;
      }, 2500);
    } else {
      toast({
        title: resultCreate.error,
        position: "top-right" as any,
        isClosable: true,
        status: "error",
      });
    }
    setDisabled(false);
  };

  return (
    <Container maxW="4xl" pt={8}>
      <Box
        py={{ base: "8", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={{ base: "transparent", sm: "bg-surface" }}
        boxShadow={{ base: "none", sm: "md" }}
        borderRadius={{ base: "xl", sm: "xl" }}
        backgroundColor="white"
      >
        <a
          onClick={() => {
            const choose = window.confirm("Sei sicuro di voler uscire?");
            if (!choose) return;
            localStorage.removeItem("token");
            localStorage.removeItem("isLibrary");
            window.location.href = "/login";
          }}
          href="#"
          style={{
            float: "right",
            color: "#000",
            fontSize: "14px",
            textDecoration: "underline",
          }}
        >
          Esci
        </a>
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <img
            style={{ width: "150px", margin: "0 auto" }}
            src="https://cdn.shopify.com/s/files/1/0596/4523/1157/files/Giunti-al-punto_logo.png?v=1675259635&width=286"
          ></img>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent style={{ padding: "15px" }}>
            <ModalHeader>Crea GiuntiCard</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Alert
                status="warning"
                style={{ lineHeight: "1.2em" }}
                rounded={10}
                mb={4}
                pl={8}
                pb={4}
              >
                <div className="pl-8">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AlertIcon />
                    <b>Attenzione</b>
                  </div>
                  <ul
                    className="mt-4"
                    style={{ listStyleType: "circle", marginTop: "10px" }}
                  >
                    <li>
                      Se l'email inserita è già associata ad una carta virtuale,
                      questa verrà dissociata e verrà creata una nuova carta
                      virtuale.
                    </li>
                    <li>
                      Se l'email non è associata a una carta verrà creata una
                      nuova carta virtuale. (in caso non siano presenti
                      anagrafiche su Shopify (giuntialpunto.it) verrà creata una
                      carta senza nome e cognome)
                    </li>
                  </ul>
                </div>
              </Alert>
              <FormControl mb={6} mt={2}>
                <FormLabel>Email o EAN</FormLabel>
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled={disabled}
                  backgroundColor={"white"}
                  placeholder="Es (test@giunti.it oppure 5013988698164)"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  if (disabled) return;
                  createNewVirtualCard();
                }}
                disabled={disabled}
                variant="primary"
              >
                Crea
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {successEan.show && (
          <Alert
            status="success"
            mt={8}
            style={{ lineHeight: "1.2em" }}
            rounded={8}
            mb={4}
          >
            <AlertIcon />
            E' stato creato correttaente una nuova carta virtuale per l'email{" "}
            {successEan.email} con EAN: {successEan.ean_code}
          </Alert>
        )}

        <FormControl mb={6} mt={8}>
          <FormLabel>Ricerca per email o EAN</FormLabel>
          <Input
            backgroundColor={"white"}
            placeholder="Ricerca per email o EAN"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            mt={4}
            w={"120px"}
            size="sm"
            borderRadius={9}
            onClick={handleSearch}
          >
            Ricerca
          </Button>
          <Button
            backgroundColor={"#005E54"}
            color="white"
            borderRadius={9}
            boxShadow="md"
            size="sm"
            ml={4}
            variant="secondary"
            mt={4}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Associa nuova GiuntiCard
          </Button>
        </FormControl>

        {loading ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <br></br>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <br></br>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <br></br>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : (
          result.name && (
            <TableContainer>
              <Table variant="simple">
                <TableCaption>
                  Dati di <u>FidelityTest</u>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Cognome Nome</Th>
                    <Th>Email</Th>
                    <Th>Codice GiuntiCard</Th>
                    <Th isNumeric>Vai al profilo</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      {result.surname} {result.name}
                    </Td>
                    <Td>{result.email}</Td>
                    <Td>
                      {result.ean_code ? result.ean_code : "Nessuna card"}
                    </Td>
                    <Td isNumeric>
                      {result.ean_code ? (
                        <Link
                          to={
                            result.email
                              ? `/email/${result.email}`
                              : `/card/${result.ean_code}`
                          }
                        >
                          <u>Vai al profilo →</u>
                        </Link>
                      ) : (
                        <p>Nessuna card</p>
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )
        )}
      </Box>
    </Container>
  );
};

export default SearchPage;
