import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

export const ModalScores = (props: any) => (
  <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
    <ModalOverlay />
    <ModalContent style={{ padding: "15px" }}>
      <ModalHeader>Aggiungi Punti</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl mb={6} mt={2}>
          <Alert
            status="info"
            style={{ lineHeight: "1.2em" }}
            rounded={8}
            mb={4}
          >
            <AlertIcon />
            Stai per aggiungere punti alla carta: <b>{props.card}</b>
          </Alert>

          <FormLabel>Punti aggiuntivi</FormLabel>
          <Input
            type="number"
            onChange={(e) => {
              props.setEmail(parseInt(e.target.value));
            }}
            backgroundColor={"white"}
            placeholder="Numero punti"
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
        shadow={"md"}
          onClick={() => {
            props.callBack();
          }}
        >
          Crea
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
