import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Login } from './pages/login';
import SearchPage from './pages/home';
import { CardPage } from './pages/card';
import { Customer } from './pages/customer';
import Istruzioni from './pages/istrunction';

function App() {
  return (
    <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="/email/:email" element={<Customer />} />
          <Route path="/card/:card" element={<CardPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/istruzioni" element={<Istruzioni />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
