/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { PasswordField } from "../components/PasswordField";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();

  const login = async () => {
    const result = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/users/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    ).then((res) => res.json());
    if (result.jwt) {
      localStorage.setItem(
        "isLibrary",
        email == "f.mattei@giunti.it" ? "false" : "true"
      );
      localStorage.setItem("token", result.jwt);
      window.location.href = "/";
    } else {
      toast({
        title: "Credenziali errate",
        position: "top-right" as any,
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <Container
      maxW="4xl"
      pt={8}
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg-surface" }}
          boxShadow={{ base: "none", sm: "md" }}
          borderRadius={{ base: "xl", sm: "xl" }}
          backgroundColor="white"
        >
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                style={{ width: "140px", margin: "0 auto" }}
                src="https://cdn.shopify.com/s/files/1/0596/4523/1157/files/Giunti-al-punto_logo.png?v=1675259635&width=286"
              ></img>
            </Stack>
          </Stack>
          <Stack spacing="2">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>
              <PasswordField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Stack>
            <br />
            <Button
              backgroundColor={"#e6421a"}
              color="white"
              borderRadius={25}
              boxShadow="sm"
              onClick={login}
              variant="secondary"
            >
              Accedi
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
