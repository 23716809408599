/* eslint-disable eqeqeq */
import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Skeleton,
  Stack,
  StackDivider,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import { ModalScores } from "../components/Modals";
export const Customer = (props: any) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [scoresInput, setScoresInput] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLibrary, setIsLibrary] = useState(true);

  const toast = useToast();
  const toastIdRef = useRef();

  const [result, setResult] = useState({
    email: "",
    name: "",
    cardNumber: "",
    surname: "",
    card_balance: 0,
    card_balance_updated: 0,
    ean_code: null,
    password: "",
    expiration_date: "",
    last_operation: "",
  });

  const handePermission = async () => {
    const isLibrary = await localStorage.getItem("isLibrary");

    setIsLibrary(isLibrary == "true" ? true : false);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      props.history.push("/login");
    }
    handePermission();
    const email: string | undefined = location.pathname.split("/").pop();
    if (email) handleSearch(email);
  }, []);

  useEffect(() => {
    const email: string | undefined = location.pathname.split("/").pop();
    if (email) handleSearch(email);
  }, [forceRefresh]);

  const createNewVirtualCard = async () => {
    setLoading(true);
    const resultCreate = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/dissociate-and-create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ email: result.email }),
      }
    ).then((res) => res.json());
    if (resultCreate.status == "OK") {
      toast({
        title: `Carta creata con successo`,
        position: "top-right" as any,
        isClosable: true,
        status: "success",
      });
      setIsOpen(false);
    } else {
      toast({
        title: resultCreate.error,
        position: "top-right" as any,
        isClosable: true,
        status: "error",
      });
    }
    setForceRefresh(true);
    setLoading(false);
  };

  const downloadPdf = async () => {
    const resultPdf = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/pdf/${result.ean_code}`,
      {
        method: "GET",
        headers: {
          responseType: "blob",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    await axios(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/pdf/${result.ean_code}`,
      {
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((e) => {
        console.error(e);
        toast({
          title: `Errore nel download del pdf`,
          position: "top-right" as any,
          isClosable: true,
          status: "error",
        });
      });
  };

  const addPoints = async () => {
    setIsOpen(false);
    toastIdRef.current = toast({
      status: "info",
      position: "top-right" as any,
      render: () => (
        <Box
          color="white"
          px={4}
          pt={2}
          pb={2}
          backgroundColor={"#3182ce"}
          borderRadius="md"
        >
          <b style={{ marginRight: "5px" }}>Sto aggiungendo i punti</b>{" "}
          <Spinner size={"sm"} />
        </Box>
      ),
    }) as any;
    const resultPoint = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          ean_code: result.ean_code,
          scores: scoresInput,
        }),
      }
    ).then((res) => res.json());
    toast.close(toastIdRef.current as any);
    if (resultPoint.status == "KO") {
      toast({
        title: `Errore nell'aggiunta dei punti`,
        position: "top-right" as any,
        isClosable: true,
        status: "error",
      });
    } else {
      const newBalance = result.card_balance + scoresInput;
      localStorage.setItem("balance_" + result.ean_code, newBalance.toString());
      const date = new Date();
      localStorage.setItem(
        "last_operation_" + result.ean_code,
        date.toLocaleDateString()
      );
      toast({
        title: `Punti aggiunti con successo`,
        position: "top-right" as any,
        isClosable: true,
        status: "success",
      });
    }
  };

  const disassociateCard = async () => {
    setLoading(true);
    const resultDissaciate = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/dissociate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ ean_code: result.ean_code }),
      }
    ).then((res) => res.json());
    if (resultDissaciate.status == "OK") {
      toast({
        title: `Carta dissociata con successo`,
        position: "top-right" as any,
        isClosable: true,
        status: "success",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } else {
      toast({
        title: `Errore nella disassociazione della carta`,
        position: "top-right" as any,
        isClosable: true,
        status: "error",
      });
    }
    setLoading(false);
  };

  const handleSearch = async (email: string) => {
    setLoading(true);
    const result = await fetch(
      `${process.env.REACT_APP_BACKED_URL}/v1/fidelity/card/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ term: email }),
      }
    ).then((res) => res.json());
    if (result.status == "KO") {
      setError(true);
      setResult({
        email: "",
        surname: "",
        name: "",
        cardNumber: "",
        card_balance: 0,
        card_balance_updated: 0,
        ean_code: null,
        password: "",
        expiration_date: "",
        last_operation: "",
      });
    } else {
      //Check if in local storage there is a balance for this card and the last operation date
      const balance = localStorage.getItem("balance_" + result.ean_code);
      const lastOperation = localStorage.getItem(
        "last_operation_" + result.ean_code
      );
      if (balance) {
        result.card_balance_updated = parseInt(balance);
        result.last_operation = lastOperation;
      }
      setResult({
        email: result.email,
        name: result.name,
        surname: result.surname,
        cardNumber: result.cardNumber,
        card_balance: result.card_balance,
        ean_code: result.ean_code,
        card_balance_updated: result.card_balance_updated,
        password: result.password,
        last_operation: result.last_operation,
        expiration_date: result.expiration_date,
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <Container maxW="4xl" pt="8">
        <ModalScores
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          callBack={addPoints}
          card={result.ean_code}
          setEmail={(scores: number) => setScoresInput(scores)}
        />
        {loading ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <br></br>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <br></br>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <br></br>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : error ? (
          <Alert status="error">
            <AlertIcon />
            Non è stato trovato alcun cliente con questa email
          </Alert>
        ) : (
          <Card pt={6} pb={6} borderRadius={9} boxShadow="md">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <img
                style={{ width: "150px", margin: "0 auto" }}
                src="https://cdn.shopify.com/s/files/1/0596/4523/1157/files/Giunti-al-punto_logo.png?v=1675259635&width=286"
              ></img>
            </Stack>

            <CardHeader>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`/`}>
                    <Button w={"90px"} size="xs" borderRadius={9} color={"gray"}>
                      Home
                    </Button>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <Button w={"90px"} size="xs" borderRadius={9} color={"black"}>
                    Cliente
                  </Button>
                </BreadcrumbItem>
              </Breadcrumb>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                {result.card_balance_updated &&
                  result.card_balance_updated != 0 && (
                    <Alert
                      status="warning"
                      style={{ lineHeight: "1.2em" }}
                      rounded={8}
                      mb={4}
                    >
                      <AlertIcon />
                      Hai già aggiunto i punti per questa carta, il nuovo saldo
                      sarà disponibile il giorno successivo.
                      <br></br>
                      Il nuovo saldo sarà {result.card_balance_updated} punti e
                      l'ultima operazione è stata effettuata il{" "}
                      {result.last_operation}
                    </Alert>
                  )}
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Email
                  </Heading>
                  <p>{result.email}</p>
                </Box>

                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Cognome Nome
                  </Heading>
                  <p>
                    {result.surname ?? ""} {result.name ?? ""}
                  </p>
                </Box>

                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Carta associata
                  </Heading>
                  <p>{result.ean_code}</p>
                </Box>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Password
                  </Heading>
                  <p>{result.password}</p>
                </Box>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Data scadenza
                  </Heading>
                  <p>{new Date(result.expiration_date).toLocaleDateString()}</p>
                </Box>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Bilancio punti
                  </Heading>
                  <p>{result.card_balance}</p>
                </Box>
              </Stack>
              <br />
              <Flex>
                <Button
                  backgroundColor={"#ED8B16"}
                  color="white"
                  borderRadius={9}
                  display={isLibrary ? "none" : "block"}
                  boxShadow="md"
                  variant="secondary"
                  size="sm"
                  mt={4}
                  onClick={() => setIsOpen(true)}
                >
                  Aggiungi punti
                </Button>
                <Button
                  backgroundColor={"#003547"}
                  color="white"
                  borderRadius={9}
                  boxShadow="md"
                  display={isLibrary ? "none" : "block"}
                  size="sm"
                  ml={4}
                  variant="secondary"
                  mt={4}
                  onClick={() => {
                    const choose = window.confirm(
                      "Sei sicuro di voler disassociare la carta? Questa operazione non può essere annullata ed è reversibile solo tramite il supporto tecnico"
                    );
                    if (choose) disassociateCard();
                  }}
                >
                  Disassocia carta
                </Button>
                <Button
                  backgroundColor={"#005E54"}
                  color="white"
                  borderRadius={9}
                  boxShadow="md"
                  onClick={() => {
                    //Alert with "are you sure?"
                    const choose = window.confirm(
                      "Sei sicuro di voler creare una nuova carta?"
                    );
                    if (choose) createNewVirtualCard();
                  }}
                  size="sm"
                  ml={isLibrary ? 0 : 4}
                  variant="secondary"
                  mt={4}
                >
                  Associa nuova carta
                </Button>
                <Button
                  backgroundColor={"#E1523D"}
                  color="white"
                  borderRadius={9}
                  boxShadow="md"
                  onClick={() => {
                    downloadPdf();
                  }}
                  size="sm"
                  ml={4}
                  variant="secondary"
                  mt={4}
                >
                  Scarica PDF carta
                </Button>
              </Flex>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};
