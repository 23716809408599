import { Box, Heading, Text, List, ListItem, Divider, Stack } from "@chakra-ui/react";
function Istruzioni() {
    return (
        <Box maxW="600px" mx="auto" py="8">
            <a
                onClick={() => {
                    window.location.href = "/";
                }}
                href="#"
                style={{ float: "right", color: "#000", fontSize: "14px", textDecoration: "underline" }}
            >Home</a>
            <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <img style={{ width: "180px", margin: "0 auto" }} src="https://cdn.shopify.com/s/files/1/0596/4523/1157/files/Giunti-al-punto_logo.png?v=1675259635&width=286"></img>
            </Stack>
            <Heading as="h1" mb="4" mt="8">
                Istruzioni per l'utilizzo del software
            </Heading>
            <Text mb="8">
                Di seguito sono riportati i passaggi per utilizzare il software:
            </Text>
            <List spacing="4">
                <ListItem>
                    <Text fontWeight="bold">Passo 1:</Text> Accedi al software utilizzando le tue credenziali.
                </ListItem>
                <ListItem>
                    <Text fontWeight="bold">Passo 2:</Text> Seleziona la funzione che desideri utilizzare dalla barra laterale.
                </ListItem>
                <ListItem>
                    <Text fontWeight="bold">Passo 3:</Text> Inserisci le informazioni richieste nel modulo e premi il pulsante "Invia".
                </ListItem>
                <ListItem>
                    <Text fontWeight="bold">Passo 4:</Text> Verifica i risultati ottenuti e, se necessario, apporta eventuali modifiche.
                </ListItem>
            </List>
            <Divider my="8" />
            <Text>
                Se hai bisogno di ulteriori informazioni, contatta il nostro servizio clienti al numero 123456789.
            </Text>
        </Box>
    );
}

export default Istruzioni;
